import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

interface FormatDateOptions {
  showTimestamp?: boolean;
  onlyTimestamp?: boolean;
}

/**
 *
 * @param {*} date
 */
export function formatDate(date: string | Date, options?: FormatDateOptions) {
  let pattern = 'dd/MM/yyyy - hh:mm:ss';

  const dateString = new Date(date.toString());
  const formatOptions = {
    locale: ptBR,
  };
  if (!options) return format(dateString, pattern, formatOptions);
  if (options.onlyTimestamp) {
    pattern = 'hh:mm:ss';
    return format(dateString, pattern, formatOptions);
  }
  if (!options.showTimestamp) {
    pattern = 'dd/MM/yyyy';
  }
  return format(dateString, pattern, formatOptions);
}
