const INVALID_CREDENTIALS = 'InvalidCredentials';
const UNAVAILABLE_OPERATION = 'UnavailableOperation';
const EXPIRED_SESSION = 'ExpiredSession';
const INVALID_SESSION_KEY = 'InvalidSessionKey';
const SESSION_NOT_FOUND = 'SessionNotFound';
const DATA_NOT_FOUND = 'NotDataFound';
const COSTUMER_CREATE_ERROR = 'createCustomerError';
const INVALID_SMS_TOKEN = 'validateSMSTokenError';
const CHECK_REGISTERED_CUSTOMER_ERROR = 'CheckCustomerRegisteredError';
const INVALID_MSISDN = 'ValidateMsisdnError';

const errors = {
  [EXPIRED_SESSION]: 'Sua sessão expirou, por favor, faça um novo login',
  [INVALID_SESSION_KEY]: 'Sua sessão expirou, por favor, faça um novo login',
  [INVALID_CREDENTIALS]: 'Ops, verifique seu login e senha',
  [UNAVAILABLE_OPERATION]: 'Está ação está indisponível',
  [SESSION_NOT_FOUND]: 'Sessão expirada',
  [DATA_NOT_FOUND]: 'Dado não encontrado',
  [COSTUMER_CREATE_ERROR]:
    'Erro ao criar conta, favor contactar nossa central de atendimento',
  [INVALID_SMS_TOKEN]: 'Token inválido',
  [CHECK_REGISTERED_CUSTOMER_ERROR]:
    'Erro ao criar sua conta, tente novamente ou entre em contato com o nosso atendimento.',
  [INVALID_MSISDN]: 'Número inválido',

  UNAVAILABLE_SERVICE: 'Serviço indisponível',
  CONNECTION_ERROR: 'Erro de conexão com o serviço',
  AUTHENTICATION_ERROR:
    'Não conseguimos realizar sua recarga por algum motivo. Tente novamente ou entre em contato com o emissor do cartão.',
  ERROR_ON_MAKE_SALE:
    'Não conseguimos realizar sua recarga por algum motivo. Tente novamente ou entre em contato com o emissor do cartão.',
  UNAVAILABLE_CONNECTION_CHANNEL:
    'Não conseguimos realizar sua recarga por algum motivo. Tente novamente ou entre em contato com o emissor do cartão.',
  INVALID_PARAM: 'Informação inválida',
  REQUIRED_FIELD_NOT_PROVIDED: 'Campo obrigatório não foi preenchido',
  UNEXPECTED_ERROR:
    'Não conseguimos realizar sua recarga por algum motivo. Tente novamente ou entre em contato com o emissor do cartão.',
  SALE_NOT_FOUND:
    'Não conseguimos realizar sua recarga por algum motivo. Tente novamente ou entre em contato com o emissor do cartão.',
  WAS_NOT_POSSIBLE_CONFIRM_SALE:
    'Não conseguimos realizar sua recarga por algum motivo. Tente novamente ou entre em contato com o emissor do cartão.',
  WAS_NOT_POSSIBLE_CANCEL_SALE:
    'Não conseguimos realizar sua recarga por algum motivo. Tente novamente ou entre em contato com o emissor do cartão.',
  SALE_NOT_PERFORMED:
    'Não conseguimos realizar sua recarga por algum motivo. Tente novamente ou entre em contato com o emissor do cartão.',
  LOCKED_PHONE: 'Telefone bloqueado',
  RESET_TRANSACTION:
    'Não conseguimos realizar sua recarga por algum motivo. Tente novamente ou entre em contato com o emissor do cartão.',
  INVALID_PHONE: 'Telefone inválido',
  VALUE_NOT_ALLOWED:
    'Não conseguimos realizar sua recarga pois o valor informado é inválido. Tente novamente com um valor válido.',
  WITHOUT_COMMUNICATION:
    'Não conseguimos realizar sua recarga por algum motivo. Tente novamente ou entre em contato com o emissor do cartão.',
  INVALID_CARD: 'Cartão inválido',
  ERROR_ON_CHECKOUT:
    'Não conseguimos realizar sua recarga por algum motivo. Tente novamente ou entre em contato com o emissor do cartão.',
  CONSUMER_NOT_FOUND:
    'Não conseguimos realizar sua recarga por algum motivo. Tente novamente ou entre em contato com o emissor do cartão.',
  CARD_NOT_FOUND: 'Cartão não encontrado',
  ERROR_ON_MAXI_PAGO:
    'Não conseguimos realizar sua recarga por algum motivo. Tente novamente ou entre em contato com o emissor do cartão.',
  INVALID_RECEIPT: 'Comprovante inválido',
  PAGE_LIMIT_EXCEEDED: 'Limite de páginas excedido',
  ADDRESS_NOT_FOUND: 'Endereço não encontrado',
  ADDRESS_LIMIT_EXCEEDED: 'Limite de endereços excedido',
  CARD_LIMIT_EXCEEDED: 'Limite de cartões excedido',
};

function translateMessage(message?: keyof typeof errors): string {
  if (!message || !errors[message]) return errors.ERROR_ON_CHECKOUT;
  return errors[message];
}

export default translateMessage;
