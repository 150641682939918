import { useContext, useMemo } from 'react';
import { AuthContext, defaultValue } from './context';
import { AuthContextData } from './props';

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context || context == defaultValue) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
}
