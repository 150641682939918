import React, { FC, useMemo, memo, useRef, useEffect } from 'react';
import { useAuth } from 'core';
import { Helmet } from 'react-helmet';
import { Redirect, Route as DomRoute } from 'react-router-dom';
import { LOGIN, HOME } from 'routes/router';
import Loading from './components/Loading';
import { RouteProps } from './props';
import { Main } from './styles';

const Route: FC<RouteProps> = props => {
  const { title, access, component, ...rest } = props;

  const {
    isAuthenticated,
    requestProfileStatus,
    requestTokenStatus,
    token,
    tokenRestored,
  } = useAuth();

  const routeTitle = useMemo(() => {
    if (!title) return 'Brisamóvel';
    return `${title} | Brisamóvel`;
  }, [title]);

  const redirectLink = useMemo(() => {
    if (access === 'auth' && !isAuthenticated) return LOGIN;
    if (access === 'guest' && isAuthenticated) return HOME;
    return null;
  }, [access, isAuthenticated]);

  if (!tokenRestored) return <Loading />;
  if (!isAuthenticated && token) return <Loading />;
  if (redirectLink) return <Redirect to={redirectLink} />;

  return (
    <>
      <Helmet>
        <title>{routeTitle}</title>
      </Helmet>
      <Main>
        <DomRoute component={component} {...rest} />
      </Main>
    </>
  );
};

export default memo(Route);
