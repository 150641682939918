import * as Yup from 'yup';

export const recoverPasswordSchema = Yup.object().shape({
  token: Yup.string(),
  password: Yup.string()
    .min(6, 'A senha precisa ter no minímo 6 digitos, letras e números')
    .matches(/[0-9]/, 'A senha precisa ter no minímo 1 número')
    .matches(/[a-zA-Z]/, 'A senha precisa ter no minímo 1 letra')
    .required('Campo obrigatório'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password')], 'Senhas não conferem')
    .required('Campo obrigatório'),
});
