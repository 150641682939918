import React, {
  createContext,
  useCallback,
  useEffect,
  FC,
  useMemo,
  useReducer,
  memo,
} from 'react';
import base64 from 'base-64';
import { api } from '../../api';
import { useModals } from '../modals';
import { setupInterceptors } from './interceptors';
import { AuthProviderProps } from './props';
import { AuthContextData, LoginCredentials } from './props';
import { authReducer, authInitialState } from './reducer';
import { clearToken, getToken, setToken } from './storage';

export const defaultValue = {} as AuthContextData;
export const AuthContext = createContext(defaultValue);

export const AuthProvider: FC<AuthProviderProps> = memo(props => {
  const { children, storage } = props;

  const [state, dispatch] = useReducer(authReducer, authInitialState);
  const { closeModal } = useModals();

  const isAuthenticated = useMemo(
    () => state.user != null && state.token != null,
    [state.user, state.token],
  );

  const signOut = useCallback(async () => {
    closeModal();
    await clearToken(storage);
    dispatch({ type: 'SIGN_OUT' });
  }, [closeModal, storage]);

  const getProfile = useCallback(async () => {
    try {
      const token = await getToken(storage);

      dispatch({ type: 'REQUEST_PROFILE' });
      const { data } = await api.get('/customer', {
        headers: { authorization: `Bearer ${token?.accessToken}` },
      });
      dispatch({ type: 'REQUEST_PROFILE_SUCCESS', payload: data });
    } catch (e) {
      dispatch({ type: 'REQUEST_PROFILE_ERROR' });
    }
  }, [storage]);

  const signIn = useCallback(
    async (credentials: LoginCredentials) => {
      try {
        dispatch({ type: 'REQUEST_USER' });
        const { email, password } = credentials;
        const authorization = `Basic ${base64.encode(email + ':' + password)}`;

        const response = await api.get('/auth/login', {
          headers: {
            authorization,
          },
        });
        await setToken(storage, response.data);

        dispatch({ type: 'REQUEST_USER_SUCCESS', payload: response.data });
      } catch (error) {
        if (error.response) {
          dispatch({
            type: 'REQUEST_USER_ERROR',
            payload: 'Email ou Senha incorretos',
          });
        } else {
          dispatch({ type: 'REQUEST_USER_ERROR', payload: 'Ocorreu um erro' });
        }
      }
    },
    [storage],
  );

  useEffect(() => {
    async function restoreToken() {
      const token = await getToken(storage);
      dispatch({ type: 'RESTORE_TOKEN', payload: token });
    }

    restoreToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log(`Bearer ${state.token?.accessToken}`);

    api.defaults.headers.authorization = `Bearer ${state.token?.accessToken}`;

    const interceptors = setupInterceptors({
      token: state.token,
      onLogout: signOut,
      onTokenChange: async token => {
        await setToken(storage, token);
        dispatch({ type: 'SET_TOKEN', payload: token });
      },
    });

    return () => {
      api.interceptors.response.eject(interceptors.responseInterceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.token]);

  const value = useMemo(
    () => ({ ...state, isAuthenticated, signIn, signOut, getProfile }),
    [getProfile, isAuthenticated, signIn, signOut, state],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
});
