import React, { FC, memo, useEffect } from 'react';
import Fade from '@material-ui/core/Fade';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import MuiAlertTitle from '@material-ui/lab/AlertTitle';
import { Toast, useToast } from 'core';

const ToastItem: FC<Toast> = props => {
  const { title, message, timeout = 3000, type } = props;

  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast();
    }, timeout);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [removeToast, timeout]);

  return (
    <Snackbar
      message={message}
      open
      TransitionComponent={Fade}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MuiAlert elevation={6} variant="filled" severity={type}>
        {title && <MuiAlertTitle>{title}</MuiAlertTitle>}
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export default memo(ToastItem);
