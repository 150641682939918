import {
  REQUEST_STATUS_ERROR,
  REQUEST_STATUS_LOADING,
  REQUEST_STATUS_SUCCESS,
  REQUEST_STATUS_INITIAL,
} from '../../api';
import { CardsState, CardsActions } from './props';

export const cardsInitialState: CardsState = {
  cards: [],
  requestCardsStatus: REQUEST_STATUS_INITIAL,
};

export function cardsReducer(
  state: CardsState,
  action: CardsActions,
): CardsState {
  switch (action.type) {
    case 'REQUEST_CARDS':
      return {
        ...state,
        requestCardsStatus: REQUEST_STATUS_LOADING,
        cards: [],
      };
    case 'REQUEST_CARDS_SUCCESS':
      return {
        ...state,
        requestCardsStatus: REQUEST_STATUS_SUCCESS,
        cards: action.payload,
      };
    case 'REQUEST_CARDS_ERROR':
      return {
        ...state,
        requestCardsStatus: REQUEST_STATUS_ERROR,
        cards: [],
      };
    case 'RESET':
      return cardsInitialState;
    default:
      return state;
  }
}
