import React, {
  createContext,
  useCallback,
  FC,
  useReducer,
  useMemo,
} from 'react';
import { api } from '../../api';
import {
  RechargeDataResponse,
  RechargesContextData,
  RechargesContextProviderProps,
  RechargesResponse,
  SaveCardData,
} from './props';
import { rechargesReducer, rechargesInitialState } from './reducer';

export const rechargesContextDefaultValue = {} as RechargesContextData;
export const RechargesContext = createContext(rechargesContextDefaultValue);

export const RechargesProvider: FC<RechargesContextProviderProps> = props => {
  const { children, initialProps } = props;

  const [state, dispatch] = useReducer(
    rechargesReducer,
    initialProps || rechargesInitialState,
  );

  const getRecharges = useCallback(async () => {
    try {
      dispatch({ type: 'REQUEST_RECHARGES' });
      if (
        state.rechargeMeta != null &&
        state.rechargeMeta.page > state.rechargeMeta.maxPages
      ) {
        return;
      }

      const { data } = await api.get<RechargesResponse>('/recharge', {
        params: {
          page: (state.rechargeMeta?.page || 0) + 1,
        },
      });

      dispatch({ type: 'REQUEST_RECHARGES_SUCCESS', payload: data });
    } catch (error) {
      dispatch({ type: 'REQUEST_RECHARGES_ERROR' });
    }
  }, [state.rechargeMeta]);

  const addCardToSave = useCallback((card: SaveCardData) => {
    dispatch({ type: 'ADD_CARD_TO_SAVE', payload: card });
  }, []);

  const addRechargeResult = useCallback((card: RechargeDataResponse) => {
    dispatch({ type: 'ADD_RECHARGE_RESPONSE', payload: card });
  }, []);

  const reset = useCallback(() => {
    dispatch({ type: 'RESET' });
  }, []);

  const clearCardToSave = useCallback(() => {
    dispatch({ type: 'CLEAR_CARD_TO_SAVE' });
  }, []);

  const clearRechargeResponse = useCallback(() => {
    dispatch({ type: 'CLEAR_RECHARGE_RESPONSE' });
  }, []);

  const value = useMemo(
    () => ({
      ...state,
      getRecharges,
      reset,
      addCardToSave,
      addRechargeResult,
      clearCardToSave,
      clearRechargeResponse,
    }),
    [
      addCardToSave,
      addRechargeResult,
      clearCardToSave,
      clearRechargeResponse,
      getRecharges,
      reset,
      state,
    ],
  );

  return (
    <RechargesContext.Provider value={value}>
      {children}
    </RechargesContext.Provider>
  );
};
