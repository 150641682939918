import { useState, useCallback } from 'react';
import { api } from '../../../api';

function useValidateEmail() {
  const [status, setStatus] = useState({
    valid: false,
    loading: false,
    message: '',
  });

  const validateEmail = useCallback(async (email: string) => {
    try {
      setStatus(prev => ({ ...prev, loading: true, message: '' }));
      const response = await api.get(`/customer/available`, {
        params: {
          userName: email,
        },
      });
      const isValid = response.data.available;
      setStatus(prev => ({
        ...prev,
        loading: false,
        valid: isValid,
        message: isValid ? '' : 'Email já cadastrado',
      }));
    } catch (error) {
      setStatus(prev => ({
        ...prev,
        loading: false,
        valid: false,
        message: 'Ocorreu um erro ao verificar email',
      }));
    }
  }, []);

  return { validateEmail, status };
}

export default useValidateEmail;
