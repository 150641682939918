import { useMemo } from 'react';
import { useNumbers } from './useNumbers';

export function useNumbersStatus() {
  const context = useNumbers();
  const status = useMemo(
    () => context.requestNumbersStatus,
    [context.requestNumbersStatus],
  );
  return status;
}
