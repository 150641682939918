import React, { FC } from 'react';
import { ThemeProvider as StyledProvider } from 'styled-components';
import loadable from '@loadable/component';
import {
  ThemeProvider as MaterialProvider,
  CssBaseline,
  StylesProvider,
} from '@material-ui/core';
import {
  AuthProvider,
  NumbersProvider,
  UsageProvider,
  ModalsProvider,
  ToastProvider,
} from 'core';
import useReactGA from 'hooks/useReactGA';
import useSentry from 'hooks/useSentry';
import materialTheme from 'styles/themes/material-ui';
import NotificationContainer from './components/ToastContainer';
import Routes from './routes';
import { authStorage } from './utils/AuthStorage';

const GlobalStyles = loadable(() => import('styles/global'));

const App: FC = () => {
  useReactGA();
  useSentry();

  return (
    <MaterialProvider theme={materialTheme}>
      <StylesProvider injectFirst>
        <StyledProvider theme={materialTheme}>
          <GlobalStyles />
          <CssBaseline />
          <ToastProvider>
            <NotificationContainer>
              <ModalsProvider>
                <AuthProvider storage={authStorage}>
                  <NumbersProvider>
                    <UsageProvider>
                      <Routes />
                    </UsageProvider>
                  </NumbersProvider>
                </AuthProvider>
              </ModalsProvider>
            </NotificationContainer>
          </ToastProvider>
        </StyledProvider>
      </StylesProvider>
    </MaterialProvider>
  );
};

export default App;
