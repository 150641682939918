import { api } from '../../api';
import { AuthToken } from '../../interfaces';

interface SetupInterceptorsOptions {
  onLogout(): void;
  token: AuthToken | null;
  onTokenChange(token: AuthToken): Promise<void>;
}

export function setupInterceptors({
  onLogout,
  token,
  onTokenChange,
}: SetupInterceptorsOptions) {
  const responseInterceptor = api.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config;

      if (!originalRequest.url?.includes('login')) {
        if (error.response?.status === 401 && !token?.accessToken) {
          onLogout();
          return Promise.reject(error);
        }

        if (error.response?.status === 401 && token?.accessToken) {
          const { data } = await api.post('/auth/refresh-session', {
            refreshToken: token?.refreshToken,
          });
          if (data) {
            // 1) put token to LocalStorage
            await onTokenChange(data);

            // 2) Change Authorization header
            originalRequest.headers.authorization = `Bearer ${data.accessToken}`;
            // 3) return originalRequest object with Axios.
            return api(originalRequest);
          }
        }
      }
      return Promise.reject(error);
    },
  );

  return {
    responseInterceptor,
  };
}
