import { useState, useCallback } from 'react';
import {
  REQUEST_STATUS_INITIAL,
  REQUEST_STATUS_LOADING,
  api,
  REQUEST_STATUS_SUCCESS,
  REQUEST_STATUS_ERROR,
} from '../../../api';
import { RequestStatus } from '../../../interfaces';
import { FirstAccessVerifyToken } from '../props';

export default function useValidateToken() {
  const [validateTokenStatus, setStatus] = useState<RequestStatus>(
    REQUEST_STATUS_INITIAL,
  );
  const validateToken = useCallback(async (value: FirstAccessVerifyToken) => {
    try {
      if (!value.code) {
        throw new Error('CODE not defined');
      }
      setStatus(REQUEST_STATUS_LOADING);
      await api.get(`/msisdn/validate-sms-token/${value.code}`, {
        timeout: 450000,
      });

      setStatus(REQUEST_STATUS_SUCCESS);
    } catch (error) {
      if (error.response) {
        setStatus({
          ...REQUEST_STATUS_ERROR,
          errorMessage: 'Código incorreto',
        });
      } else {
        setStatus({
          ...REQUEST_STATUS_ERROR,
          errorMessage: 'Ocorreu um erro, tente novamente mais tarde',
        });
      }
    }
  }, []);

  return { validateToken, validateTokenStatus };
}
