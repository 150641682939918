interface FormatCurrencyOptions {
  value: number | string;
  showCurrencyPreffix?: boolean;
}
export function formatCurrency(options: FormatCurrencyOptions) {
  const { value, showCurrencyPreffix = false } = options;
  const number = Number(value);
  if (showCurrencyPreffix) {
    return `R$ ${number.toFixed(2).replace('.', ',')}`;
  }
  return number.toFixed(2).replace('.', ',');
}
