import { useMemo } from 'react';
import { useUsage } from './useUsage';

export function useUsageItems() {
  const context = useUsage();
  const items = useMemo(() => {
    if (!context.usage?.itens) return [];
    return context.usage.itens;
  }, [context.usage]);
  return items;
}
