import React, {
  createContext,
  useCallback,
  useEffect,
  FC,
  useMemo,
  useReducer,
} from 'react';
import { api } from '../../api';
import { useAuth } from '../auth';
import { useCurrentMSISDN } from '../number';
import {
  UsageContextData,
  UsageContextProviderProps,
  UsageItem,
  UsageResponse,
} from './props';
import { usageReducer, usageInitialState } from './reducer';

export const usageContextDefaultValue = {} as UsageContextData;
export const UsageContext = createContext(usageContextDefaultValue);

export const UsageProvider: FC<UsageContextProviderProps> = props => {
  const { children, initialProps } = props;

  const [state, dispatch] = useReducer(
    usageReducer,
    initialProps || usageInitialState,
  );

  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch({ type: 'RESET' });
    }
  }, [isAuthenticated]);

  const currentMsisdn = useCurrentMSISDN();
  useEffect(() => {
    if (currentMsisdn) {
      getUsage(currentMsisdn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMsisdn]);

  const orderItems = useCallback((items: UsageItem[]) => {
    if (items.length == 0) return [];
    const networkIndex = items.findIndex(
      item => item.name.toUpperCase() == 'DADOS',
    );

    const ordered = items
      .filter((_, index) => index != networkIndex)
      .sort((a, b) => {
        const nameA = a.type.toUpperCase();
        const nameB = b.type.toUpperCase();

        if (nameA > nameB) return 1;
        if (nameA < nameB) return -1;

        return 0;
      });
    return [items[networkIndex], ...ordered];
  }, []);

  const getUsage = useCallback(
    async (msisdn: string) => {
      try {
        dispatch({ type: 'REQUEST_USAGE' });

        const { data } = await api.get<UsageResponse>('/msisdn/usage', {
          params: { msisdn },
        });

        data.itens = orderItems(data.itens);

        dispatch({ type: 'REQUEST_USAGE_SUCCESS', payload: data });
      } catch (e) {
        dispatch({ type: 'REQUEST_USAGE_ERROR' });
      }
    },
    [orderItems],
  );

  return (
    <UsageContext.Provider
      value={{
        ...state,
        getUsage,
      }}
    >
      {children}
    </UsageContext.Provider>
  );
};
