import React, { FC, useEffect } from 'react';
import phoneIcon from 'assets/images/icons/phone.svg';
import Typography from 'components/Typography';
import { useAuth } from 'core';
import * as Styled from './styles';

const Loading: FC = () => {
  const { getProfile } = useAuth();

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <Styled.Container>
      <Styled.PhoneIcon src={phoneIcon} />

      <Typography color="secondary" variant="h5">
        Carregando dados, por favor aguarde
      </Typography>
    </Styled.Container>
  );
};

export default Loading;
