import { useMemo } from 'react';
import { useNumbers } from './useNumbers';

export function useCurrentNumber() {
  const context = useNumbers();
  const currentNumber = useMemo(
    () => context.currentNumber,
    [context.currentNumber],
  );
  return currentNumber;
}
