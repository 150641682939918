import { createTheme, responsiveFontSizes } from '@material-ui/core';
import { ptBR } from '@material-ui/core/locale';

const materialTheme = createTheme(
  {
    typography: {
      fontFamily: ['Barlow', 'Roboto', 'sans-serif'].join(','),
      h3: {
        fontWeight: 500,
      },
      h5: {
        fontWeight: 500,
      },
    },
    palette: {
      primary: {
        main: '#8cc83c',
        contrastText: '#fff',
      },
      secondary: {
        main: '#1d306a',
        contrastText: '#fff',
      },
      warning: {
        main: '#FFCB66',
      },
      error: {
        main: '#FF563A',
      },
      background: {
        default: '#fff',
      },
    },
    overrides: {
      MuiButton: {
        outlined: {
          borderColor: '#FFFFFF',
          color: '#FFFFFF',
          textTransform: 'none',
        },
        containedPrimary: {
          boxShadow: '0 3px 6px 0 rgba(128, 241, 0, 0.38)',
        },
        text: {
          textTransform: 'none',
        },
        contained: {
          textTransform: 'none',
        },
      },
    },
  },
  ptBR,
);

export default responsiveFontSizes(materialTheme);
