import React, { useLayoutEffect } from 'react';
import ReactGA from 'react-ga';

function useReactGA() {
  useLayoutEffect(() => {
    ReactGA.initialize('UA-151102057-1', {
      debug: process.env.NODE_ENV === 'development',
      testMode: process.env.NODE_ENV === 'development',
    });
  }, []);
}

export default useReactGA;
