import {
  REQUEST_STATUS_ERROR,
  REQUEST_STATUS_LOADING,
  REQUEST_STATUS_SUCCESS,
  REQUEST_STATUS_INITIAL,
} from '../../api';
import { RechargesState, RechargesActions } from './props';

export const rechargesInitialState: RechargesState = {
  recharges: [],
  requestRechargesStatus: REQUEST_STATUS_INITIAL,
  rechargeMeta: null,
  usedCard: null,
  rechargeResult: null,
};

export function rechargesReducer(
  state: RechargesState,
  action: RechargesActions,
): RechargesState {
  switch (action.type) {
    case 'REQUEST_RECHARGES':
      return {
        ...state,
        requestRechargesStatus: REQUEST_STATUS_LOADING,
      };
    case 'REQUEST_RECHARGES_SUCCESS': {
      return {
        ...state,
        requestRechargesStatus: REQUEST_STATUS_SUCCESS,
        recharges: [...state.recharges, ...action.payload.data],
        rechargeMeta: action.payload.meta,
      };
    }
    case 'REQUEST_RECHARGES_ERROR':
      return {
        ...state,
        requestRechargesStatus: REQUEST_STATUS_ERROR,
      };
    case 'ADD_CARD_TO_SAVE':
      return {
        ...state,
        usedCard: action.payload,
      };
    case 'CLEAR_CARD_TO_SAVE':
      return {
        ...state,
        usedCard: null,
      };
    case 'ADD_RECHARGE_RESPONSE':
      return {
        ...state,
        rechargeResult: action.payload,
      };
    case 'CLEAR_RECHARGE_RESPONSE':
      return {
        ...state,
        rechargeResult: null,
      };
    case 'RESET':
      return rechargesInitialState;
    default:
      return state;
  }
}
