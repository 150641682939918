export const authStorage = {
  getItem: async (key: string): Promise<string | null> => {
    const rawToken = localStorage.getItem(key);
    if (!rawToken) return null;
    return rawToken;
  },
  setItem: async (key: string, item: string): Promise<void> => {
    localStorage.setItem(key, item);
  },
  removeItem: async (key: string): Promise<void> => {
    localStorage.removeItem(key);
  },
};
