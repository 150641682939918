import React, {
  createContext,
  useCallback,
  FC,
  useReducer,
  useEffect,
} from 'react';
import { api } from '../../api';
import { useAuth } from '../auth';
import { CardItem, CardsContextData, CardsContextProviderProps } from './props';
import { cardsReducer, cardsInitialState } from './reducer';
export const cardsContextDefaultValue = {} as CardsContextData;
export const CardsContext = createContext(cardsContextDefaultValue);

export const CardsProvider: FC<CardsContextProviderProps> = props => {
  const { children, initialProps } = props;
  const [state, dispatch] = useReducer(
    cardsReducer,
    initialProps || cardsInitialState,
  );

  const { isAuthenticated } = useAuth();
  useEffect(() => {
    if (!isAuthenticated) {
      dispatch({ type: 'RESET' });
    }
  }, [isAuthenticated]);

  const getCards = useCallback(async () => {
    try {
      dispatch({ type: 'REQUEST_CARDS' });
      const { data } = await api.get<CardItem[]>('/card');

      dispatch({ type: 'REQUEST_CARDS_SUCCESS', payload: data });
    } catch (error) {
      dispatch({ type: 'REQUEST_CARDS_ERROR' });
    }
  }, []);

  return (
    <CardsContext.Provider
      value={{
        ...state,
        getCards,
      }}
    >
      {children}
    </CardsContext.Provider>
  );
};
