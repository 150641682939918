import { AuthToken } from '../../interfaces';
import { AuthStorage } from './props';

const AUTH_STORAGE_KEY = '@Brisamovel:token';

export async function getToken(storage: AuthStorage) {
  const rawToken = await storage.getItem(AUTH_STORAGE_KEY);
  if (!rawToken) return null;
  const token = JSON.parse(rawToken);
  if (!token) return null;
  return token as AuthToken;
}

export async function setToken(storage: AuthStorage, token: AuthToken | null) {
  if (token) {
    await storage.setItem(AUTH_STORAGE_KEY, JSON.stringify(token));
  } else {
    await storage.removeItem(AUTH_STORAGE_KEY);
  }
}

export async function clearToken(storage: AuthStorage) {
  await storage.removeItem(AUTH_STORAGE_KEY);
  await storage.getItem(AUTH_STORAGE_KEY);
}
