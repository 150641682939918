import {
  REQUEST_STATUS_ERROR,
  REQUEST_STATUS_LOADING,
  REQUEST_STATUS_SUCCESS,
  REQUEST_STATUS_INITIAL,
} from '../../api';
import { AddressesState, AddressesActions } from './props';

export const addressesInitialState: AddressesState = {
  addresses: [],
  requestAddressesStatus: REQUEST_STATUS_INITIAL,
};

export function addressesReducer(
  state: AddressesState,
  action: AddressesActions,
): AddressesState {
  switch (action.type) {
    case 'REQUEST_ADDRESSES':
      return {
        ...state,
        requestAddressesStatus: REQUEST_STATUS_LOADING,
        addresses: [],
      };
    case 'REQUEST_ADDRESSES_SUCCESS':
      return {
        ...state,
        requestAddressesStatus: REQUEST_STATUS_SUCCESS,
        addresses: action.payload,
      };
    case 'REQUEST_ADDRESSES_ERROR':
      return {
        ...state,
        requestAddressesStatus: REQUEST_STATUS_ERROR,
        addresses: [],
      };
    default:
      return state;
  }
}
