import { useState, useEffect, useCallback, useMemo } from 'react';
import useCreateUser from './useCreateUser';
import useFirstAccess from './useFirstAccess';

export default function useResendSMS() {
  const context = useCreateUser();
  const { userData } = useFirstAccess();

  const [remainingSeconds, setRemainingSeconds] = useState(60);

  const canResend = useMemo(() => remainingSeconds == 0, [remainingSeconds]);

  useEffect(() => {
    let timer: null | ReturnType<typeof setTimeout> = null;
    if (remainingSeconds > 0) {
      timer = setTimeout(() => {
        setRemainingSeconds(remainingSeconds - 1);
      }, 1000); // 60 segundos
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [canResend, remainingSeconds]);

  const resendSMS = useCallback(() => {
    setRemainingSeconds(60);
    context.createUser(userData);
  }, [context, userData]);

  return {
    canResend,
    resendSMS,
    remainingSeconds,
  };
}
