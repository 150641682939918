import { useMemo } from 'react';
import { formatDate } from '../../../utils/formatDate';
import { formatBytes, timeConvert } from '../../../utils/usageFormat';
import { UsageItem } from '../props';
import { useUsagePercentage } from './useUsagePercentage';
import { useUsageTranslatedName } from './useUsageTranslatedName';
import { useUsageUnlimited } from './useUsageUnlimited';
import { useUsageValues } from './useUsageValues';

export function useUsageDisplay(props: UsageItem) {
  const {
    name,
    startDate,
    expirationDate,
    currentAccountCounter,
    initialAccountCounter,
    type,
    unitsAccountCounter,
    endValueAccountCounter,
  } = props;

  const useUsageOptions = {
    currentAccountCounter,
    initialAccountCounter,
    type,
    unitsAccountCounter,
    endValueAccountCounter,
  };

  const translatedName = useUsageTranslatedName(name);
  const isUnlimited = useUsageUnlimited(useUsageOptions);
  const { freePercent, usedPercent } = useUsagePercentage(useUsageOptions);
  const { freeValue, usedValue } = useUsageValues(useUsageOptions);

  const expiryDate = useMemo(() => {
    if (expirationDate == null) return null;
    const endDateFormatted = formatDate(expirationDate);
    return `Válido até ${endDateFormatted}`;
  }, [expirationDate]);

  const formattedUsedPercent = useMemo(() => {
    if (usedPercent == null) return null;
    return `${usedPercent.toFixed(2).replace('.', ',')}%`;
  }, [usedPercent]);

  const formattedFreePercent = useMemo(() => {
    if (freePercent == null) return null;
    return `${freePercent.toFixed(2).replace('.', ',')}%`;
  }, [freePercent]);

  const formattedUsedValue = useMemo(() => {
    if (usedValue == null) return null;
    if (type === 'voz') return timeConvert(usedValue);
    if (type === 'dados') return formatBytes(usedValue);
    return usedValue;
  }, [type, usedValue]);

  const formattedFreeValue = useMemo(() => {
    if (typeof freeValue === 'string') return freeValue;
    if (type === 'voz') return timeConvert(freeValue);
    if (type === 'dados') return formatBytes(freeValue);
    return freeValue;
  }, [freeValue, type]);

  const startLabel = useMemo(() => {
    if (isUnlimited) return 'Ilimitado';
    if (type === 'dados') return '0 GB';
    if (type === 'voz') return '0 min';
    return '0';
  }, [isUnlimited, type]);

  const endLabel = useMemo(() => {
    if (isUnlimited) return undefined;
    if (type === 'dados') return formatBytes(initialAccountCounter);
    if (type === 'voz') return timeConvert(initialAccountCounter);
    return '0';
  }, [initialAccountCounter, isUnlimited, type]);

  const progressBarCurrent = useMemo(
    () => (isUnlimited ? 0 : usedValue),
    [isUnlimited, usedValue],
  );

  const progressBarMax = useMemo(
    () => (isUnlimited ? 100 : initialAccountCounter),
    [initialAccountCounter, isUnlimited],
  );

  return {
    isUnlimited,
    freeValue,
    usedValue,
    translatedName,
    progressBarMax,
    progressBarCurrent,
    endLabel,
    startLabel,
    formattedFreeValue,
    formattedFreePercent,
    expiryDate,
    formattedUsedPercent,
    formattedUsedValue,
  };
}
