import { useMemo } from 'react';
import { useUsage } from './useUsage';

export function useUsageStatus() {
  const context = useUsage();
  const status = useMemo(
    () => context.requestUsageStatus,
    [context.requestUsageStatus],
  );
  return status;
}
