import { useContext } from 'react';
import { FirstAccessContext, firstAccessContextDefaultValue } from '../context';

export default function useFirstAccess() {
  const context = useContext(FirstAccessContext);
  if (!context || context === firstAccessContextDefaultValue) {
    throw new Error('useFirstAccess must be used within a FirstAccessProvider');
  }
  return context;
}
