import { useState, useCallback } from 'react';
import {
  REQUEST_STATUS_INITIAL,
  REQUEST_STATUS_LOADING,
  api,
  REQUEST_STATUS_SUCCESS,
  REQUEST_STATUS_ERROR,
} from '../../../api';
import { RequestStatus } from '../../../interfaces';
import { removeSymbols } from '../../../utils';
import { FirstAccessCreateUser } from '../props';
import useFirstAccess from './useFirstAccess';

export default function useCreateUser() {
  const { setUserData, userData, setCurrentStep } = useFirstAccess();
  const [createUserStatus, setStatus] = useState<RequestStatus>(
    REQUEST_STATUS_INITIAL,
  );

  const createUser = useCallback(
    async (user: FirstAccessCreateUser) => {
      try {
        setStatus(REQUEST_STATUS_LOADING);
        const { email, firstName, lastName, password } = user;
        const newUser = {
          firstName,
          lastName,
          password,
          userName: email,
          msisdn: removeSymbols(userData.msisdn || ''),
        };
        await api.post('/customer', newUser);
        setUserData(prev => ({ ...prev, user }));
        setStatus(REQUEST_STATUS_SUCCESS);
        setCurrentStep('checkSMSToken');
      } catch (error) {
        setStatus({ ...REQUEST_STATUS_ERROR, errorMessage: error.message });
      }
    },
    [setCurrentStep, setUserData, userData],
  );

  return { createUser, createUserStatus };
}
