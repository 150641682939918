/**
 * Return size with suffix 'Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB' or 'YB'
 * @param bytes size in bytes
 * @param decimals floating point lenght (default: `2`)
 */
export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))
    .toString()
    .replace('.', ',')} ${sizes[i]}`;
}

export function timeConvert(duration: number) {
  // const milliseconds = parseInt(((duration % 1000) / 100).toString(), 10);
  // const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  const seconds = Math.floor((duration / 1000) % 60);
  const minutes = Math.floor((duration / (1000 * 60)) % 60);

  if (minutes > 0) return `${minutes}min`;
  return `${seconds}s`;
}
