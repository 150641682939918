import { useState, useCallback } from 'react';
import {
  REQUEST_STATUS_INITIAL,
  REQUEST_STATUS_LOADING,
  api,
  REQUEST_STATUS_SUCCESS,
  REQUEST_STATUS_ERROR,
} from '../../../api';
import { RequestStatus } from '../../../interfaces';
import {
  RecoverCreatePasswordCredentials,
  RecoverPasswordCredentials,
} from '../props';

export function useRecoverPassword() {
  const [requestRecoverPasswordStatus, setStatus] = useState<RequestStatus>(
    REQUEST_STATUS_INITIAL,
  );

  const recoverPassword = useCallback(
    async (credentials: RecoverPasswordCredentials) => {
      try {
        setStatus(REQUEST_STATUS_LOADING);
        await api.get(`/auth/recovery/send-mail/${credentials.email}`, {
          timeout: 450000,
        });
        setStatus(REQUEST_STATUS_SUCCESS);
      } catch (err) {
        setStatus(REQUEST_STATUS_ERROR);
      }
    },
    [],
  );

  return { requestRecoverPasswordStatus, recoverPassword };
}

export function useCreateNewPassword() {
  const [requestRecoverPasswordStatus, setStatus] = useState<RequestStatus>(
    REQUEST_STATUS_INITIAL,
  );

  const createNewPassword = useCallback(
    async (credentials: RecoverCreatePasswordCredentials) => {
      try {
        setStatus(REQUEST_STATUS_LOADING);
        await api.post(
          `/auth/recovery/recovery-password/${credentials.token}`,
          { password: credentials.password },
        );
        setStatus(REQUEST_STATUS_SUCCESS);
      } catch (err) {
        setStatus(REQUEST_STATUS_ERROR);
      }
    },
    [],
  );

  const resetState = useCallback(() => {
    setStatus(REQUEST_STATUS_INITIAL);
  }, []);

  return { requestRecoverPasswordStatus, createNewPassword, resetState };
}
