import { useMemo } from 'react';
import { UseUsagePercentageOptions } from '../props';
import { useUsageUnlimited } from './useUsageUnlimited';

export function useUsageValues(options: UseUsagePercentageOptions) {
  const { currentAccountCounter, initialAccountCounter } = options;

  const isUnlimited = useUsageUnlimited(options);

  const usedValue = useMemo(
    () => initialAccountCounter - currentAccountCounter,
    [currentAccountCounter, initialAccountCounter],
  );

  const freeValue = useMemo(() => {
    if (isUnlimited) return 'Ilimitado';
    return currentAccountCounter;
  }, [currentAccountCounter, isUnlimited]);

  return {
    usedValue,
    freeValue,
  };
}
