import { useContext } from 'react';
import { UsageContext, usageContextDefaultValue } from '../context';

export function useUsage() {
  const context = useContext(UsageContext);

  if (!context || context == usageContextDefaultValue) {
    throw new Error('useUsage must be used within a UsageProvider');
  }

  return context;
}
