import { useState, useCallback } from 'react';
import {
  REQUEST_STATUS_INITIAL,
  REQUEST_STATUS_LOADING,
  api,
  REQUEST_STATUS_SUCCESS,
  REQUEST_STATUS_ERROR,
} from '../../../api';
import { RequestStatus } from '../../../interfaces';
import { removeSpaces, removeSymbols } from '../../../utils';
import { FirstAccessCheckNumber } from '../props';
import useFirstAccess from './useFirstAccess';

export default function useCheckNumber() {
  const { setUserData, setCurrentStep } = useFirstAccess();
  const [checkNumberStatus, setStatus] = useState<RequestStatus>(
    REQUEST_STATUS_INITIAL,
  );

  const checkNumber = useCallback(
    async (value: FirstAccessCheckNumber) => {
      try {
        if (!value.msisdn) {
          throw new Error('Número vazio');
        }
        setStatus(REQUEST_STATUS_LOADING);

        const { data } = await api.get('/msisdn/available', {
          params: {
            msisdn: removeSymbols(removeSpaces(value.msisdn)),
          },
        });

        const { registeredsc, firstName, lastName } = data.CustomerDataDetails;
        if (registeredsc == null) {
          throw new Error('Número não encontrado');
        }
        if (registeredsc == 1) {
          throw new Error('Número já cadastrado');
        }
        setUserData(prev => ({
          ...prev,
          msisdn: value.msisdn,
          firstName,
          lastName,
        }));

        setStatus(REQUEST_STATUS_SUCCESS);
        setCurrentStep('personalData');
      } catch (error) {
        setStatus({ ...REQUEST_STATUS_ERROR, errorMessage: error.message });
      }
    },
    [setCurrentStep, setUserData],
  );

  return {
    checkNumber,
    checkNumberStatus,
  };
}
