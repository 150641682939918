import React, {
  createContext,
  useCallback,
  useEffect,
  FC,
  useMemo,
  useReducer,
} from 'react';
import { api } from '../../api';
import { CustomerAccount } from '../../interfaces';
import { useAuth } from '../auth';
import {
  NumbersContextData,
  NumbersContextProviderProps,
  NumbersSetCurrentNumber,
} from './props';
import { numbersReducer, numbersInitialState } from './reducer';

export const numbersContextDefaultValue = {} as NumbersContextData;
export const NumbersContext = createContext(numbersContextDefaultValue);

export const NumbersProvider: FC<NumbersContextProviderProps> = props => {
  const { children, initialProps } = props;

  const [state, dispatch] = useReducer(
    numbersReducer,
    initialProps || numbersInitialState,
  );

  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch({ type: 'RESET' });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (state.numbers.length > 0) {
      dispatch({ type: 'SET_CURRENT_NUMBER', payload: 0 });
    }
  }, [state.numbers]);

  const currentNumber = useMemo(() => {
    if (state.numbers.length == 0 || state.currentNumberIndex === -1)
      return null;
    return state.numbers[state.currentNumberIndex];
  }, [state.numbers, state.currentNumberIndex]);

  const setCurrentNumber = useCallback<NumbersSetCurrentNumber>(
    newNumber => {
      if (state.currentNumberIndex === -1 || !newNumber) return;

      if (typeof newNumber === 'string') {
        const numberIndex = state.numbers.findIndex(
          number => number.msisdn === newNumber,
        );
        if (numberIndex != -1) {
          dispatch({ type: 'SET_CURRENT_NUMBER', payload: numberIndex });
        }
      } else {
        const numberIndex = state.numbers.findIndex(
          number => number.msisdn === newNumber.target.value,
        );
        if (numberIndex != -1) {
          dispatch({ type: 'SET_CURRENT_NUMBER', payload: numberIndex });
        }
      }
    },
    [state.currentNumberIndex, state.numbers],
  );

  const getNumbers = useCallback(async () => {
    try {
      dispatch({ type: 'REQUEST_NUMBERS' });
      const { data } = await api.get<CustomerAccount[]>('/msisdn');
      dispatch({ type: 'REQUEST_NUMBERS_SUCCESS', payload: data });
    } catch (error) {
      dispatch({ type: 'REQUEST_NUMBERS_ERROR' });
    }
  }, []);

  return (
    <NumbersContext.Provider
      value={{
        ...state,
        currentNumber,
        setCurrentNumber,
        getNumbers,
      }}
    >
      {children}
    </NumbersContext.Provider>
  );
};
