import React, {
  createContext,
  useCallback,
  useEffect,
  FC,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { ModalNames, ModalsContextData } from './props';

export const modalsContextDefaultValue = {} as ModalsContextData;
export const ModalsContext = createContext(modalsContextDefaultValue);

export const ModalsProvider: FC = props => {
  const { children } = props;

  const [activeModal, setActiveModal] = useState<ModalNames | null>(null);

  const closeModal = useCallback(() => {
    setActiveModal(null);
  }, []);

  const openModal = useCallback(
    (name: ModalNames) => {
      if (activeModal != null) {
        closeModal();
        setTimeout(() => {
          setActiveModal(name);
        }, 400);
      } else {
        setActiveModal(name);
      }
    },
    [activeModal, closeModal],
  );

  return (
    <ModalsContext.Provider value={{ activeModal, openModal, closeModal }}>
      {children}
    </ModalsContext.Provider>
  );
};
