import { useLayoutEffect } from 'react';
import * as Sentry from '@sentry/browser';

function useSentry() {
  useLayoutEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      Sentry.init({
        dsn: 'https://23f23a1abbaf4c54851b04134fe48324@o353260.ingest.sentry.io/5200371',
      });
    }
  }, []);
}

export default useSentry;
