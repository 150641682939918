import { useMemo } from 'react';
import { UseUsagePercentageOptions } from '../props';
import { useUsageUnlimited } from './useUsageUnlimited';

export function useUsagePercentage(options: UseUsagePercentageOptions) {
  const { currentAccountCounter, initialAccountCounter } = options;

  const isUnlimited = useUsageUnlimited(options);

  const usedPercent = useMemo(() => {
    if (isUnlimited) return null;
    const percent = 100 - 100 / (initialAccountCounter / currentAccountCounter);
    return percent;
  }, [currentAccountCounter, initialAccountCounter, isUnlimited]);

  const freePercent = useMemo(() => {
    if (isUnlimited || usedPercent == null) return null;
    const percent = 100 / (initialAccountCounter / currentAccountCounter);
    return percent;
  }, [isUnlimited, usedPercent, initialAccountCounter, currentAccountCounter]);

  return {
    usedPercent,
    freePercent,
  };
}
