import { validateBr } from '@b3coded/js-brasil';
import cardValidator from 'card-validator';
import * as Yup from 'yup';
import { removeCountryCode } from '../utils/formatNumber';

export const rechargeSchema = Yup.object().shape({
  name: Yup.string().when('card.id', {
    is: (value: string) => !value,
    then: Yup.string().required('Campo obrigatório'),
    otherwise: Yup.string(),
  }),
  number: Yup.string()
    .test(
      'number',
      'Número inválido',
      number => number && validateBr.telefone(removeCountryCode(number)),
    )
    .required('Campo obrigatório'),
  amount: Yup.string().required('Campo obrigatório'),
  ownerCard: Yup.boolean(),
  cpf: Yup.string().when('ownerCard', {
    is: false,
    then: Yup.string().required('Campo obrigatório'),
  }),
  card: Yup.object().shape({
    id: Yup.string(),
    cvv: Yup.string()
      .test('cvv', 'Código inválido', cvv => cardValidator.cvv(cvv).isValid)
      .required('Campo obrigatório'),
    exp: Yup.string().when('id', {
      is: (value: string) => !value,
      then: Yup.string()
        .matches(
          /[0-9]{2}\/[0-9]{4}/,
          'Data inválida, use 4 números para o ano',
        )
        .test(
          'exp',
          'Expiração inválida',
          exp => cardValidator.expirationDate(exp).isValid,
        )
        .required('Campo obrigatório'),
    }),
    number: Yup.string().when('id', {
      is: (value: string) => !value,
      then: Yup.string()
        .test(
          'cardNumber',
          'Número inválida',
          cardNumber => cardValidator.number(cardNumber).isValid,
        )
        .required('Campo obrigatório'),
    }),
  }),
});
