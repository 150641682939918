import { useMemo, useState, useCallback, useEffect, useContext } from 'react';
import { ModalsContext, modalsContextDefaultValue } from '../context';

export function useModals() {
  const context = useContext(ModalsContext);
  if (!context || context == modalsContextDefaultValue) {
    throw new Error('useModals must be used within a ModalsProvider');
  }
  return context;
}
