import { validateBr } from '@b3coded/js-brasil';
import validate from 'card-validator';
import * as Yup from 'yup';
import { removeCountryCode } from '../utils';

const addressIdValidation = {
  is: (value?: string) => !value,
  then: Yup.string().required('Campo obrigatório'),
};

export const createCardSchema = Yup.object().shape({
  ownerCard: Yup.boolean(),
  cpf: Yup.string().when('ownerCard', {
    is: false,
    then: Yup.string()
      .required('Campo obrigatório')
      .test('isCPF', 'CPF inválido', value => value && validateBr.cpf(value)),
  }),
  ownerPhone: Yup.string().when('ownerCard', {
    is: false,
    then: Yup.string()
      .required('Campo obrigatório')
      .test(
        'number',
        'Número inválido',
        number => number && validateBr.telefone(removeCountryCode(number)),
      ),
  }),
  ownerName: Yup.string().when('ownerCard', {
    is: false,
    then: Yup.string().required('Campo obrigatório'),
  }),
  email: Yup.string().when('ownerCard', {
    is: false,
    then: Yup.string().required('Campo obrigatório'),
  }),

  expiration: Yup.string()
    .required('Campo obrigatório')
    .matches(/[0-9]{2}\/[0-9]{4}/, 'Data inválida, use 4 números para o ano')
    .test(
      'isCardExp',
      'Data inválida',
      value => validate.expirationDate(value).isValid,
    ),
  number: Yup.string()
    .required('Campo obrigatório')
    .test(
      'isCardNumber',
      'Número inválido',
      value => validate.number(value).isValid,
    ),
  billingName: Yup.string()
    .required('Campo obrigatório')
    .uppercase('Necessário ser maiúsculo'),

  address: Yup.object().shape({
    addressId: Yup.string(),
    zip: Yup.string().when('addressId', addressIdValidation),
    street: Yup.string().when('addressId', addressIdValidation),
    city: Yup.string().when('addressId', addressIdValidation),
    state: Yup.string().when('addressId', addressIdValidation),
    neighborhood: Yup.string().when('addressId', addressIdValidation),
    complement: Yup.string(),
    number: Yup.string().when('addressId', addressIdValidation),
  }),
});
