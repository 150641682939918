import React from 'react';
import styled from 'styled-components';
import MuiTypography from '@material-ui/core/Typography';
import { TypographyProps } from './props';

export const Typography = styled(
  ({ capitalize, weight, flex, ...props }: TypographyProps) => (
    <MuiTypography {...props} />
  ),
)<TypographyProps>(({ theme, capitalize, weight, variant, flex }) => {
  if (variant == 'inherit' || variant == 'srOnly') {
    return {
      textTransform: capitalize || 'none',
      flex,
    };
  }

  let { fontWeight } = theme.typography[variant || 'body1'];
  switch (weight) {
    case 'bold':
      fontWeight = theme.typography.fontWeightBold;
      break;
    case 'regular':
      fontWeight = theme.typography.fontWeightRegular;
      break;
    case 'medium':
      fontWeight = theme.typography.fontWeightMedium;
      break;
    case 'light':
      fontWeight = theme.typography.fontWeightLight;
      break;
    default:
      fontWeight = theme.typography[variant || 'body1'].fontWeight;
      break;
  }
  return {
    textTransform: capitalize || 'none',
    fontWeight,
    flex,
  };
});
