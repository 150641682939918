import * as Yup from 'yup';

export const firstAccessTokenSchema = Yup.object().shape({
  code: Yup.string().required('Campo obrigatório'),
  terms: Yup.boolean().oneOf([true], 'Termos precisam ser aceitos'),
  privacy: Yup.boolean().oneOf(
    [true],
    'Politica de privacidade precisa ser aceita',
  ),
});
