import {
  REQUEST_STATUS_ERROR,
  REQUEST_STATUS_LOADING,
  REQUEST_STATUS_SUCCESS,
  REQUEST_STATUS_INITIAL,
} from '../../api';
import { NumbersState, NumbersActions } from './props';

export const numbersInitialState: NumbersState = {
  numbers: [],
  currentNumberIndex: -1,
  requestNumbersStatus: REQUEST_STATUS_INITIAL,
};

export function numbersReducer(
  state: NumbersState,
  action: NumbersActions,
): NumbersState {
  switch (action.type) {
    case 'REQUEST_NUMBERS':
      return {
        ...state,
        currentNumberIndex: -1,
        requestNumbersStatus: REQUEST_STATUS_LOADING,
        numbers: [],
      };
    case 'REQUEST_NUMBERS_SUCCESS':
      return {
        ...state,
        numbers: action.payload,
        requestNumbersStatus: REQUEST_STATUS_SUCCESS,
      };
    case 'REQUEST_NUMBERS_ERROR':
      return {
        ...state,
        requestNumbersStatus: REQUEST_STATUS_ERROR,
        numbers: [],
      };
    case 'SET_CURRENT_NUMBER':
      return {
        ...state,
        currentNumberIndex: action.payload,
      };
    case 'RESET':
      return numbersInitialState;
    default:
      return state;
  }
}
