import React, { FC, useMemo } from 'react';
import { ToastProvider, useToast, withContextProvider } from 'core';
import Toast from './components/Toast';
import * as Styled from './styles';

const ToastContainer: FC = props => {
  const { children } = props;

  const { toasts } = useToast();

  const listToasts = useMemo(
    () => toasts.map(e => <Toast key={e.id} {...e} />),
    [toasts],
  );

  return (
    <Styled.Container>
      {children}

      {listToasts}
    </Styled.Container>
  );
};

export default ToastContainer;
