import { validateBr } from '@b3coded/js-brasil';
import * as Yup from 'yup';

export const firstAccessNumberSchema = Yup.object().shape({
  msisdn: Yup.string()
    .test(
      'isPhone',
      'Número inválido',
      value => value && validateBr.telefone(value),
    )
    .required('Campo obrigatório'),
});
