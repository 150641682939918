import * as Yup from 'yup';

export const firstAccessPersonalDataSchema = Yup.object().shape({
  email: Yup.string().email('Email inválido').required('Campo obrigatório'),
  firstName: Yup.string().required('Campo obrigatório'),
  lastName: Yup.string().required('Campo obrigatório'),
  password: Yup.string()
    .min(6, 'A senha precisa ter no minímo 6 digitos, letras e números')
    .matches(/[0-9]/, 'A senha precisa ter no minímo 1 número')
    .matches(/[a-zA-Z]/, 'A senha precisa ter no minímo 1 letra')
    .required('Campo obrigatório'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password')], 'Senhas não conferem')
    .required('Campo obrigatório'),
});
