import React, { createContext, useCallback, FC, useReducer } from 'react';
import { api } from '../../api';
import {
  AddressesContextData,
  AddressesContextProviderProps,
  AddressesResponse,
} from './props';
import { addressesReducer, addressesInitialState } from './reducer';

export const addressesContextDefaultValue = {} as AddressesContextData;
export const AddressesContext = createContext(addressesContextDefaultValue);

export const AddressesProvider: FC<AddressesContextProviderProps> = props => {
  const { children, initialProps } = props;

  const [state, dispatch] = useReducer(
    addressesReducer,
    initialProps || addressesInitialState,
  );

  const getAddresses = useCallback(async () => {
    try {
      dispatch({ type: 'REQUEST_ADDRESSES' });
      const { data } = await api.get<AddressesResponse>('/consumer/address');

      dispatch({ type: 'REQUEST_ADDRESSES_SUCCESS', payload: data.adresses });
    } catch (error) {
      dispatch({ type: 'REQUEST_ADDRESSES_ERROR' });
    }
  }, []);

  return (
    <AddressesContext.Provider
      value={{
        ...state,
        getAddresses,
      }}
    >
      {children}
    </AddressesContext.Provider>
  );
};
