import styled, { keyframes } from 'styled-components';

export const Container = styled.div(({ theme }) => ({
  height: '100%',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const phoneAnimation = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '25%': {
    transform: 'rotate(-30deg)',
  },
  '50%': {
    transform: 'rotate(0deg)',
  },
  '75%': {
    transform: 'rotate(30deg)',
  },
  '100%': {
    transform: 'rotate(0deg)',
  },
});

// export const PhoneIcon = styled.img`
//   animation: ${phoneAnimation} infinite 2s linear;
// `;

export const PhoneIcon = styled.img(({ theme }) => ({
  animation: `${phoneAnimation.getName()} infinite 2s linear`,
}));
