import {
  REQUEST_STATUS_ERROR,
  REQUEST_STATUS_LOADING,
  REQUEST_STATUS_SUCCESS,
  REQUEST_STATUS_INITIAL,
} from '../../api';
import { AuthState, AuthActions } from './props';

export const authInitialState: AuthState = {
  requestProfileStatus: REQUEST_STATUS_INITIAL,
  requestTokenStatus: REQUEST_STATUS_INITIAL,
  token: null,
  user: null,
  tokenRestored: false,
};

export function authReducer(state: AuthState | undefined, action: AuthActions) {
  if (!state) return authInitialState;
  switch (action.type) {
    case 'REQUEST_PROFILE':
      return {
        ...state,
        requestProfileStatus: REQUEST_STATUS_LOADING,
      };

    case 'REQUEST_PROFILE_SUCCESS':
      return {
        ...state,
        requestProfileStatus: REQUEST_STATUS_SUCCESS,
        user: action.payload,
      };

    case 'REQUEST_PROFILE_ERROR':
      return {
        ...state,
        requestProfileStatus: REQUEST_STATUS_ERROR,
        user: null,
      };

    case 'REQUEST_USER':
      return {
        ...state,
        requestTokenStatus: REQUEST_STATUS_LOADING,
      };

    case 'REQUEST_USER_SUCCESS':
      return {
        ...state,
        requestTokenStatus: REQUEST_STATUS_SUCCESS,
        token: action.payload,
      };

    case 'REQUEST_USER_ERROR':
      return {
        ...state,
        requestTokenStatus: {
          ...REQUEST_STATUS_ERROR,
          errorMessage: action.payload,
        },
        user: null,
      };

    case 'SIGN_OUT':
      return {
        ...authInitialState,
        token: null,
        user: null,
        tokenRestored: true,
      };

    case 'SET_TOKEN':
      return {
        ...state,
        token: action.payload,
      };
    case 'RESTORE_TOKEN':
      return {
        ...state,
        token: action.payload,
        tokenRestored: true,
      };
    default:
      return authInitialState;
  }
}
