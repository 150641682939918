import { RequestStatus } from '../interfaces';

export const REQUEST_STATUS_INITIAL: RequestStatus = {
  error: false,
  loading: false,
  success: false,
};

export const REQUEST_STATUS_LOADING: RequestStatus = {
  error: false,
  loading: true,
  success: false,
};

export const REQUEST_STATUS_SUCCESS: RequestStatus = {
  error: false,
  loading: false,
  success: true,
};

export const REQUEST_STATUS_ERROR: RequestStatus = {
  error: true,
  loading: false,
  success: false,
};

export const BASE_URL = process.env.REACT_APP_API_URL;
