import {
  REQUEST_STATUS_ERROR,
  REQUEST_STATUS_LOADING,
  REQUEST_STATUS_SUCCESS,
  REQUEST_STATUS_INITIAL,
} from '../../api';
import { UsageActions, UsageState } from './props';

export const usageInitialState: UsageState = {
  requestUsageStatus: REQUEST_STATUS_INITIAL,
  usage: null,
  updatedAt: null,
};

export function usageReducer(
  state: UsageState,
  action: UsageActions,
): UsageState {
  switch (action.type) {
    case 'REQUEST_USAGE':
      return {
        ...state,
        requestUsageStatus: REQUEST_STATUS_LOADING,
        usage: null,
        updatedAt: null,
      };

    case 'REQUEST_USAGE_SUCCESS':
      return {
        ...state,
        requestUsageStatus: REQUEST_STATUS_SUCCESS,
        usage: action.payload,
        updatedAt: new Date(),
      };

    case 'REQUEST_USAGE_ERROR':
      return {
        ...state,
        requestUsageStatus: REQUEST_STATUS_ERROR,
        usage: null,
        updatedAt: null,
      };
    case 'RESET':
      return usageInitialState;
    default:
      return state;
  }
}
