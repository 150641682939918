import { useState, useCallback, useMemo } from 'react';

export function useShowPassword(initialState = false) {
  const [showPassword, setShowPassword] = useState(initialState);

  const toggleShowPassword = useCallback(
    () => setShowPassword(prev => !prev),
    [setShowPassword],
  );

  return {
    showPassword,
    setShowPassword,
    toggleShowPassword,
  };
}
