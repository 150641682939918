import { createContext, useContext } from 'react';
import { RequestStatus } from '../interfaces';

export interface StepperContextProps<StepNames = string> {
  step: StepNames;
  nextStep(): void;
  previousStep(): void;
  goToStep(step: StepNames): void;
  requestStatus: RequestStatus;
}

export const StepperContext = createContext<StepperContextProps<any>>(
  undefined as any,
);
export const StepperProvider = StepperContext.Provider;
export const StepperConsumer = StepperContext.Consumer;

export function useStepper<StepNames = string>() {
  const stepper = useContext<StepperContextProps<StepNames>>(StepperContext);
  if (!stepper) {
    throw new Error('useStepper should be in StepperProvider');
  }
  return stepper;
}
