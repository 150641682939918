import { useContext } from 'react';
import { NumbersContext, numbersContextDefaultValue } from '../context';

export function useNumbers() {
  const context = useContext(NumbersContext);
  if (!context || context == numbersContextDefaultValue) {
    throw new Error('useNumbers must be used within a NumbersProvider');
  }
  return context;
}
