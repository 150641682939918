import React, {
  FC,
  createContext,
  useContext,
  useCallback,
  useState,
} from 'react';
import { Toast, ToastType, ToastContextData } from './props';

export const toastContextDefaultValue = {} as ToastContextData;
export const ToastContext = createContext(toastContextDefaultValue);

export const ToastProvider: FC = props => {
  const { children } = props;

  const [toasts, setToasts] = useState<Toast[]>([]);

  const showToast = useCallback((newToast: Omit<Toast, 'id'>) => {
    const id = new Date().getTime().toString();
    const toast = { ...newToast, id };
    setToasts([toast]);
  }, []);

  const removeToast = useCallback(() => {
    setToasts([]);
  }, []);

  return (
    <ToastContext.Provider value={{ toasts, removeToast, showToast }}>
      {children}
    </ToastContext.Provider>
  );
};
