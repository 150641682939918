import { removeSymbols } from './stringUtils';

const DIGIT = '9';
const DIGIT_PATTERN = /[0-9]/;

const ALPHA = 'a';
const ALPHA_PATTERN = /[a-zA-Z]/;

const ALPHA_UPPER = 'A';
const ALPHA_UPPER_PATTERN = /[A-Z]/;

const ALPHANUM = 'S';
const ALPHANUM_PATTERN = /[0-9a-zA-Z]/;

const patternMap: Record<string, RegExp> = {
  [ALPHA]: ALPHA_PATTERN,
  [ALPHA_UPPER]: ALPHA_UPPER_PATTERN,
  [ALPHANUM]: ALPHANUM_PATTERN,
  [DIGIT]: DIGIT_PATTERN,
};

/**
 * Verifica se o caractere atual obedece ao Pattern atual
 *
 * @param char caractere atual
 * @param currentPattern tipo de caractere: `9`, `a` , `A` ou `S`
 */
function matchPattern(char: string, currentPattern: string) {
  if (!patternMap[currentPattern]) return false;
  const regexpPattern = patternMap[currentPattern];
  return regexpPattern.test(char);
}

/**
 * Mascara uma string para um padrão customizado
 *
 * @param pattern padrão de formatação
 * @description
 * descrição do padrão de formatação
 *
 * `a`: qualquer letra
 *
 * `A`: qualquer letra maiúscula
 *
 * `9`: qualquer número
 *
 * `S`: qualquer letra ou número
 *
 * @param value valor a ser mascarado
 * @returns string formatada
 */
export function maskValue(pattern: string, value: string) {
  // remove símbolos já que não serão usados na função de mascarar
  const rawValue = removeSymbols(value);
  const characters = rawValue.split('');
  let maskedValue = '';
  let currentPatternIndex = 0;
  for (let index = 0; index < characters.length; index++) {
    const char = characters[index];
    if (maskedValue.length >= pattern.length) {
      break;
    }
    const out = pattern[currentPatternIndex];
    const matchesPattern = matchPattern(char, out);
    if (matchesPattern) {
      currentPatternIndex = currentPatternIndex + 1;
      maskedValue = maskedValue.concat(char);
    } else {
      currentPatternIndex = currentPatternIndex + 1;
      index--;
      maskedValue = maskedValue.concat(out);
    }
  }
  return maskedValue;
}
