import { useMemo, useContext } from 'react';
import { ToastContext, toastContextDefaultValue } from './context';

export function useToast() {
  const context = useContext(ToastContext);

  if (!context || context == toastContextDefaultValue) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
}
