import React, { createContext, useCallback, FC, useState } from 'react';
import {
  FirstAccessContextData,
  FirstAccessUserData,
  FirstAccessSteps,
} from './props';

export const firstAccessContextDefaultValue = {} as FirstAccessContextData;
export const FirstAccessContext = createContext(firstAccessContextDefaultValue);

export const FirstAccessProvider: FC = props => {
  const { children } = props;

  const [currentStep, setCurrentStep] =
    useState<FirstAccessSteps>('checkNumber');
  const [userData, setUserData] = useState<FirstAccessUserData>({});

  const reset = useCallback(() => {
    setUserData({});
    setCurrentStep('checkNumber');
  }, []);

  return (
    <FirstAccessContext.Provider
      value={{
        userData,
        setUserData,
        currentStep,
        setCurrentStep,
        reset,
      }}
    >
      {children}
    </FirstAccessContext.Provider>
  );
};
