import React, { FC } from 'react';
import loadable from '@loadable/component';
import Route from 'components/Route';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { HOME, LOGIN, RECOVER_PASSWORD, STORES } from './router';

const Home = loadable(
  () => import(/* webpackChunkName: "home" */ 'pages/Home'),
);
const Login = loadable(
  () => import(/* webpackChunkName: "login" */ 'pages/Login'),
);
const NotFound = loadable(
  () => import(/* webpackChunkName: "not-found" */ 'pages/NotFound'),
);
const RecoverPassword = loadable(
  () => import(/* webpackChunkName: "recover" */ 'pages/RecoverPassword'),
);
const Stores = loadable(
  () => import(/* webpackChunkName: "stores" */ 'pages/Stores'),
);

const Routes: FC = () => {
  return (
    <Router>
      <Switch>
        <Route
          title="Entrar"
          path={LOGIN}
          exact
          component={Login}
          access="guest"
        />

        <Route
          title="Início"
          path={HOME}
          exact
          component={Home}
          access="auth"
        />

        <Route title="Lojas" path={STORES} exact component={Stores} />

        <Route
          title="Recuperar Senha"
          path={RECOVER_PASSWORD}
          exact
          component={RecoverPassword}
          access="guest"
        />

        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default Routes;
