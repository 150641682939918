import { extractNumbers } from './formatNumber';

export function getNumberDetails(number: string) {
  const formattedNumber = extractNumbers(number).slice(2);
  const ddd = parseInt(formattedNumber.slice(0, 2), 10);
  const phoneNumber = formattedNumber.slice(2);
  return {
    ddd,
    phoneNumber,
  };
}
