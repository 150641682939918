import { useMemo } from 'react';

export function useUsageTranslatedName(name: string) {
  const translatedName = useMemo(() => {
    const names = {
      'voz onnet': 'Ligações para Brisa Móvel, Brisa Fixo e Vivo',
      'voz offnet': 'Ligações para móvel e fixo de outras operadoras do Brasil',
      dados: 'Consumo da franquia Internet',
      sms: 'Consumo de SMS',
      'sms onnet': 'Consumo de SMS para Brisamóvel e Vivo',
    };
    if (Object.keys(names).includes(name.toLowerCase())) {
      return names[name.toLowerCase() as keyof typeof names];
    }

    const firstLetter = name.slice(0, 1).toUpperCase();
    const rest = name.slice(1).toLowerCase();

    return `${firstLetter}${rest}`.replace(/(GB){1}/gi, v => v.toUpperCase());
  }, [name]);

  return translatedName;
}
