import { formatToPattern } from './formatToPattern';
import { removeSpaces } from './stringUtils';

export function extractNumbers(value: string, addCountryCode = true) {
  const pattern = /\d+/g;
  const onlyNumbers = value.match(pattern)?.join('') || '';
  let formatted = removeSpaces(onlyNumbers);
  if (!formatted.startsWith('55') && addCountryCode) {
    formatted = '55'.concat(formatted);
  }
  return formatted;
}

export function addCountryCode(value: string) {
  if (value.startsWith('55')) return value;
  return '55'.concat(value);
}

export function removeCountryCode(value: string) {
  if (!value.startsWith('55')) return value;
  return value.replace(/^55/, '');
}

interface FormatNumberExtraOptions {
  countryCode: boolean;
}
export function formatNumber(
  value: string,
  options?: FormatNumberExtraOptions,
) {
  if (options?.countryCode) {
    const baseValue = addCountryCode(value);
    return formatToPattern('+XX (XX) X XXXX-XXXX', baseValue);
  }
  const formattedValue = removeCountryCode(value);
  return formatToPattern('(XX) X XXXX-XXXX', formattedValue);
}
