export function removeParentheses(value: string) {
  return value.replace(/([(]|[)])/g, '');
}

export function removeSpaces(value: string) {
  return value.replace(/\s/g, '');
}

export function removeTraces(value: string) {
  return value.replace(/-/, '');
}

export function removeSymbols(value: string) {
  return value.replace(/[^a-zA-Z0-9]+/gi, '');
}
