import { useMemo } from 'react';
import { UseUsagePercentageOptions } from '../props';

export function useUsageUnlimited(options: UseUsagePercentageOptions) {
  const { type, endValueAccountCounter, initialAccountCounter } = options;

  const isUnlimited = useMemo(() => {
    if (type === 'voz') {
      const minutes = initialAccountCounter / 1000 / 60;
      return minutes <= 5000;
    }
    if (type === 'sms') {
      return endValueAccountCounter === null || endValueAccountCounter == 0;
    }
    return false;
  }, [endValueAccountCounter, initialAccountCounter, type]);

  return isUnlimited;
}
